const awsconfig = {
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_g56cKTbnT',
    userPoolWebClientId: '4sd7pj4vo89nlg9dkpn9pmere7',
    mandatorySignIn: false,
    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: 'https://junctionnet-edu.auth.us-east-1.amazoncognito.com',
      scope: [
        'email',
        'profile',
        'openid',
      ],
      redirectSignIn: 'https://redlie.junctionnet.cloud/',
      redirectSignOut: 'https://redlie.junctionnet.cloud/',
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },

    // signUp : {
    //   username: email.value,
    //   password: password.value,
    //   attributes: {
    //     email: email.value,          // required
    //   },
    // }
  },
};

export default awsconfig;
